import React from 'react'
import PublicHeader from '../atoms/PublicHeader'
import { Container, Heading, Text, Button, Image, VStack, Divider } from '@chakra-ui/react'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import Card from '../../../atoms/Card'
import { useEventRecipients } from '../../../../hooks/useRecipients'
import { useEventContext } from '../../../../contexts/EventProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import PublicFooter from '../atoms/PublicFooter'
import useImageGenerator from '../../../../hooks/useImageGenerator'
// import PreviewControls from '../Active/molecules/PreviewControls'
import loadable from '@loadable/component'
import redirectToNextIfWindow from '../../../../utils/redirectToNextIfWindow'

const PreviewControls = loadable(() => import(/* webpackPrefetch: true */ '../Active/molecules/PreviewControls'), {
	ssr: false,
})

const Archived = () => {
	useScrollTopOnMount()

	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const location = useLocation()
	const handlers = useEventHandlersV2(event.uuid)
	const navigate = useNavigate()
	const { creator } = event

	const handleRedirect = () => redirectToNextIfWindow(`/auth/login?next=${handlers.root}`)
	//  navigate(`/auth/login?next=${handlers.root}`)

	const cover = useImageGenerator({
		path: '/assets/images/graphics/abandoned-vidday',
		ext: 'png',
	})
	return (
		<>
			<PreviewControls />
			<PublicHeader mb="2rem" />

			<Container maxW="container.sm" pb="1rem" variant="main" px="1rem">
				<Card p="2rem" mb="1rem" textAlign="center">
					<VStack w="full">
						<Image src={cover.src} srcSet={cover.srcset} w="167px" />
						<Heading variant="hero" as="h3" size="lg" color="dark" fontWeight="normal">
							This VidDay is inactive.
						</Heading>
						<Text color="dark">
							It's been a long time since the last activity. If you're the creator of this VidDay, log
							back into your account and make your video active again.
						</Text>
					</VStack>

					<Divider borderStyle="solid" borderColor="gray.200" maxW="100px" mx="auto" my="1rem" />

					<VStack w="full">
						<Text color="dark">Are you the creator of this video?</Text>
						<Button color="link" variant="link" onClick={handleRedirect}>
							log in
						</Button>
					</VStack>
				</Card>

				<PublicFooter />
			</Container>
		</>
	)
}

export default Archived
